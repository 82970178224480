import Vue from 'vue'
import Router from 'vue-router'
import store from '../vuex/store.js'

import AxiosPlugin from '../axios/axios.js'
Vue.use(AxiosPlugin, '$axios');

import {
	Message,
} from 'element-ui';

Vue.prototype.$message = Message;


import HYConfig from '../config/config.js'
Vue.use(HYConfig)

// import Login from '@/components/Login/login'
// const Login = () => import('@/components/Login/login')
const Login = resolve => require(['@/components/Login/login'], resolve)
//找不到页面
const NotFound = resolve => require(['@/components/NotFound/NotFound'], resolve)


const Frame = resolve => require(['@/components/Frame'], resolve)
//仓配客户管理//看板
const Home = resolve => require(['@/components/WarehouseCenter2/Home/Home'], resolve)
//帮助
const Help = resolve => require(['@/components/WarehouseCenter2/Home/Help'], resolve)
//工作台
const Workbench = resolve => require(['@/components/WarehouseCenter2/Home/Workbench'], resolve)
//地图地址距离查询
const mapAddressDistance = resolve => require(['@/components/WarehouseCenter2/Home/mapAddressDistance'], resolve)
const WhCalendar = resolve => require(['@/components/WarehouseCenter2/Home/WhCalendar'], resolve)

//仓配管理中心
//仓配产品管理
// const WhGoods = resolve => require(['@/components/WarehouseCenter/WhGoods'], resolve)
//仓配客户管理
const WhCustomer = resolve => require(['@/components/WarehouseCenter/WhCustomer'], resolve)
//仓租费用管理
const WhFeeRecordList = resolve => require(['@/components/WarehouseCenter/WhFeeRecordList'], resolve)
//仓配客户管理-全部客户-不分权限
const WhCustomerAll = resolve => require(['@/components/WarehouseCenter/WhCustomerAll'], resolve)

//财务记录
const WhFinance = resolve => require(['@/components/WarehouseCenter/WhFinance'], resolve)
const WhFinanceAdd = resolve => require(['@/components/WarehouseCenter/WhFinanceAdd'], resolve)
//财务记录明细
const WhFinanceDetList = resolve => require(['@/components/WarehouseCenter/WhFinanceDetList'], resolve)
//附加费记录
const WhFinanceAdditionalRec = resolve => require(['@/components/WarehouseCenter/WhFinanceAdditionalRec'], resolve)

//应付款管理
const AccountPayableList = resolve => require([
	'@/components/WarehouseCenter2/FinanceMana/AccountPayable/AccountPayableList'
], resolve)
const AccountPayableListAdd = resolve => require([
	'@/components/WarehouseCenter2/FinanceMana/AccountPayable/AccountPayableListAdd'
], resolve)

//应收账单
const AccountReceivableList = resolve => require([
	'@/components/WarehouseCenter2/FinanceMana/AccountReceivable/AccountReceivableList'
], resolve)

//库位管理-分页
const WhPlace = resolve => require(['@/components/WarehouseCenter/WhPlace'], resolve)
const WhPlaceCharts = resolve => require(['@/components/WarehouseCenter2/SysConfig/WhPlaceCharts'], resolve)

//仓配收货管理-新
const WhInGoods2 = resolve => require(['@/components/WarehouseCenter2/WhInGoods2'], resolve)
//仓配收货明细-新
// const WhInGoodsDetList2 = resolve => require(['@/components/WarehouseCenter2/WhInGoodsDetList2'], resolve)
//仓配收货管理-入库记录-新
const WhInsStockRecord = resolve => require(['@/components/WarehouseCenter2/WhInsStockRecord'], resolve)
//仓配收货管理-库存补录
// const WhInventoryCollection = resolve => require(['@/components/WarehouseCenter2/WhInventoryCollection'], resolve)
//仓配收货-确认收货
const WhInGoodsDetAudit = resolve => require(['@/components/WarehouseCenter2/inWarehouse/WhInGoodsDetAudit'], resolve)

//仓配发货管理-发货指令-新
const WhInShipmentsCommand = resolve => require(['@/components/WarehouseCenter2/shipments/WhInShipmentsCommand'],
	resolve)
//仓配发货管理-发货指令明细-新
// const WhInShipmentsComDetList = resolve => require(['@/components/WarehouseCenter2/shipments/WhInShipmentsComDetList'],
// 	resolve)
// const WhInShipmentsComDetListPicking = resolve => require([
// 	'@/components/WarehouseCenter2/shipments/WhInShipmentsComDetListPicking'
// ], resolve)

//扫码打印附件
const WhInShipmentsScanPrinteFile = resolve => require([
	'@/components/WarehouseCenter2/shipments/WhInShipmentsScanPrinteFile'
], resolve)

//仓配发货管理-打托
// const WhInShipmentsPallet = resolve => require(['@/components/WarehouseCenter2/shipments/WhInShipmentsPallet'], resolve)
//FBA转运
// const fbaTransshipment = resolve => require(['@/components/WarehouseCenter2/shipments/fbaTransshipment'], resolve)
//客户自提
// const selfPickUp = resolve => require(['@/components/WarehouseCenter2/shipments/selfPickUp'], resolve)

//仓配发货管理-设置出库位置
// const WhInSetOutPlace = resolve => require(['@/components/WarehouseCenter2/WhInSetOutPlace'], resolve)

//仓配-一件代发
const WhDropShipping = resolve => require(['@/components/WarehouseCenter2/WhDropShipping'], resolve)
//仓配-一件代发-统计看板
const WhDropShippingDashboard = resolve => require(['@/components/WarehouseCenter2/WhDropShippingDashboard'], resolve)

//仓配-一件代发-批次
const WhDropShippingBatch = resolve => require(['@/components/WarehouseCenter2/WhDropShippingBatch'], resolve)
//仓配-一件代发-批次明细
const WhDropShippingBatchDet = resolve => require(['@/components/WarehouseCenter2/WhDropShippingBatchDet'], resolve)
//仓配-一件代发-批次--扫码打单
const WhDropShippingBatchScanPrint = resolve => require(['@/components/WarehouseCenter2/WhDropShippingBatchScanPrint'],
	resolve)
// 一件代发-olpn--扫码打单
const WhDsOLpnScanPrint = resolve => require(['@/components/WarehouseCenter2/DropShipping/WhDsOLpnScanPrint'],
	resolve)
	
//打印流水
// const PrtExpressSheetRec = resolve => require(['@/components/WarehouseCenter2/shipments/PrtExpressSheetRec'], resolve)
const WhDropShippingDet = resolve => require(['@/components/WarehouseCenter2/WhDropShippingDet'], resolve)

//退货换标
const ExchangeReturnList = resolve => require(['@/components/WarehouseCenter2/ExchangeReturn/ExchangeReturnList'],
	resolve)
//退货换标-入库
const ExchangeReturnInsStock = resolve => require([
	'@/components/WarehouseCenter2/ExchangeReturn/ExchangeReturnInsStock'
], resolve)


//转运管理
const TransportInWh = resolve => require(['@/components/WarehouseCenter2/Transport/TransportInWh'], resolve)
const TransportInWhDetList = resolve => require(['@/components/WarehouseCenter2/Transport/TransportInWhDetList'],
	resolve)
const TransportInWhDetAudit = resolve => require(['@/components/WarehouseCenter2/Transport/TransportInWhDetAudit'],
	resolve)

const TransportOutWh = resolve => require(['@/components/WarehouseCenter2/Transport/TransportOutWh'], resolve)
// const TransportOutWhEdit = resolve => require(['@/components/WarehouseCenter2/Transport/TransportOutWhEdit'], resolve)
const TransportOutWhDetList = resolve => require(['@/components/WarehouseCenter2/Transport/TransportOutWhDetList'],
	resolve)
const TransportInventories = resolve => require(['@/components/WarehouseCenter2/Transport/TransportInventories'],
	resolve)

//预约出库-转运-代理
const ClientTransportPlanOutWh = resolve => require(['@/components/StorageCenter/Transport/ClientTransportPlanOutWh'], resolve)
const TransportPlanOutWhEdit = resolve => require(['@/components/StorageCenter/Transport/TransportPlanOutWhEdit'], resolve)

//预约出库-转运
const TransportPlanOutWh = resolve => require(['@/components/WarehouseCenter2/Transport/TransportPlanOutWh'], resolve)

//转运-代理操作
const ClientTransportInWh = resolve => require(['@/components/StorageCenter/Transport/ClientTransportInWh'], resolve)
const ClientTransportInWhEdit = resolve => require(['@/components/StorageCenter/Transport/ClientTransportInWhEdit'],
	resolve)
const ClientTransportInWhDetList = resolve => require([
	'@/components/StorageCenter/Transport/ClientTransportInWhDetList'], resolve)
const ClientTransportInWhByToOut = resolve => require([
	'@/components/StorageCenter/Transport/ClientTransportInWhByToOut'], resolve)
const ClientTransportOutWh = resolve => require(['@/components/StorageCenter/Transport/ClientTransportOutWh'], resolve)
const ClientTransportOutWhEdit = resolve => require(['@/components/StorageCenter/Transport/ClientTransportOutWhEdit'],
	resolve)
const ClientTransportOutWhDetList = resolve => require([
	'@/components/StorageCenter/Transport/ClientTransportOutWhDetList'
], resolve)
// const ClientTransportInventories = resolve => require(['@/components/StorageCenter/Transport/TransportInventories'], resolve)
const whSkuSplitAgent = resolve => require([
	'@/components/StorageCenter/workOrder/workOrderSimList/whSkuSplitAgent'
], resolve)


//工单管理
const WhWorkOrderList = resolve => require(['@/components/WarehouseCenter2/WhWorkOrderList'], resolve)
const WhWorkOrderListDet = resolve => require(['@/components/WarehouseCenter2/WhWorkOrderListDet'], resolve)
// const WorkDropShipping = resolve => require(['@/components/WarehouseCenter2/workOrder/WorkDropShipping'], resolve)
// const WorkDropShippingList = resolve => require(['@/components/WarehouseCenter2/workOrder/WorkDropShippingList'],
// resolve)
// const WorkTransshipmentList = resolve => require(['@/components/WarehouseCenter2/workOrder/WorkTransshipmentList'],
// 	resolve)
//仓配-一件代发-打单明细
const PriterOrderDetList = resolve => require(['@/components/WarehouseCenter2/PriterOrderDetList'], resolve)
//工单-收货入库
// const WorkWhPacingList = resolve => require(['@/components/WarehouseCenter2/workOrder/WorkWhPacingList'], resolve)
//工单-退货换标
// const WorkExchangeReturnList = resolve => require(['@/components/WarehouseCenter2/workOrder/WorkExchangeReturnList'],
// resolve)

//授权页面
// const AuthFrame = resolve => require(['@/components/Auth/AuthFrame'], resolve)
// const UpsOauth = resolve => require(['@/components/Auth/UpsOauth'], resolve)
// const UpsOauthBack = resolve => require(['@/components/Auth/UpsOauthBack'], resolve)


//打印
const PrinterFrame = resolve => require(['@/printer/PrinterFrame'], resolve)
// const whPrinterInGood = resolve => require(['@/printer/whPrinterInGood'], resolve)
// const whPriterShipments = resolve => require(['@/printer/whPriterShipments'], resolve)
//收货清单
const whPrinterReceptionList = resolve => require(['@/printer/whPrinterReceptionList'], resolve)
//拣货清单
const whPriterPickingList = resolve => require(['@/printer/whPriterPickingList'], resolve)
//一件代发-拣货清单
const whPriterDropShippingPickingList = resolve => require(['@/printer/whPriterDropShippingPickingList'], resolve)
//工单-一件代发-拣货清单
// const whPriterDropShippingPickingListByWorkNo = resolve => require([
// 	'@/printer/whPriterDropShippingPickingListByWorkNo'
// ], resolve)
//转运清单
//入库
const whPriterTransportInList = resolve => require(['@/printer/whPriterTransportInList'], resolve)
//出库
const whPriterTransportOutList = resolve => require(['@/printer/whPriterTransportOutList'], resolve)

//增值服务清单
const whPrinterValueAddList = resolve => require(['@/printer/whPrinterValueAddList'], resolve)

//预约出库-转运-提货单
const whPriterTransportPlanOutList = resolve => require(['@/printer/whPriterTransportPlanOutList'], resolve)

//FBA标签
// const whPriterDropFBAPicking = resolve => require(['@/printer/whPriterDropFBAPicking'], resolve)

//异常件管理
const WhExceptionalEvent = resolve => require(['@/components/WarehouseCenter2/WhExceptionalEvent'], resolve)
const WhExceptionalEventRecord = resolve => require(['@/components/WarehouseCenter2/WhExceptionalEventRecord'], resolve)

//客户SKU
// const WhCustomerSkuList = resolve => require(['@/components/WarehouseCenter2/WhCustomerSkuList'], resolve)
//客户SKU库存
const WhCustomerSkuInventories = resolve => require(['@/components/WarehouseCenter2/WhCustomerSkuInventories'], resolve)
//SKU录入
const WSkuInfo = resolve => require(['@/components/WarehouseCenter2/WSkuInfo'], resolve)
//客户SKU流水
const WhCustomerSkuRecord = resolve => require(['@/components/WarehouseCenter2/WhCustomerSkuRecord'], resolve)
//客户SKU库存-扣除
const WhCustomerSkuInventoriesChangeStock = resolve => require([
	'@/components/WarehouseCenter2/WhCustomerSkuInventoriesChangeStock'
], resolve)

//客户SKU库存已锁定
const WhSkuStockLock = resolve => require([
	'@/components/WarehouseCenter2/SkuProduct/WhSkuStockLock'
], resolve)

//库存明细
const WhStockList = resolve => require([
	'@/components/WarehouseCenter2/SkuPlace/WhStockList'
], resolve)

//库存盘点
const WhStockCheck = resolve => require([
	'@/components/WarehouseCenter2/SkuPlace/WhStockCheck'
], resolve)

//报表管理
//库存明细
const InventoryStatistics = resolve => require(['@/components/WarehouseCenter2/statement/InventoryStatistics'], resolve)
//库存汇总
const InventoryStatisticsSkuGroup = resolve => require([
	'@/components/WarehouseCenter2/statement/InventoryStatisticsSkuGroup'
], resolve)
//库存实时查询
const InventoryNow = resolve => require(['@/components/WarehouseCenter2/statement/InventoryNow'], resolve)
//出库报表
const outBill = resolve => require(['@/components/WarehouseCenter2/statement/outBill'], resolve)
//每月订单统计
const OrderStatisticsByMon = resolve => require(['@/components/WarehouseCenter2/statement/OrderStatisticsByMon'],
	resolve)
//月订单每日统计
// const OrderStatisticsByDay = resolve => require(['@/components/WarehouseCenter2/statement/OrderStatisticsByDay'], resolve)
//仓库货量统计
const CargoVolumeStatisticsByDay = resolve => require(['@/components/WarehouseCenter2/statement/CargoVolumeStatisticsByDay'],
	resolve)

//仓库费用统计
const WhCostStatistics = resolve => require(['@/components/WarehouseCenter2/warehouseCost/WhCostStatistics'], resolve)
//收费标准配置
const ChargeConfiguration = resolve => require(['@/components/WarehouseCenter2/warehouseCost/ChargeConfiguration'],
	resolve)

//账号绑定仓库配置
const AccountBindWh = resolve => require(['@/components/WarehouseCenter2/AccountBind/AccountBindWh'], resolve)
const QuotationConfig = resolve => require(['@/components/WarehouseCenter2/CostConfig/QuotationConfig'], resolve)

//客户绑定仓库配置
const CustomerBindWh = resolve => require(['@/components/WarehouseCenter2/AccountBind/CustomerBindWh'], resolve)
const CustomerBindWhAdmin = resolve => require(['@/components/WarehouseCenter2/AccountBind/CustomerBindWhAdmin'], resolve)

//账户信息
const WhAccountList = resolve => require(['@/components/WarehouseCenter2/AccountMana/WhAccountList'], resolve)
const WhAccountRec = resolve => require(['@/components/WarehouseCenter2/AccountMana/WhAccountRec'], resolve)
const WhRechargeRec = resolve => require(['@/components/WarehouseCenter2/AccountMana/WhRechargeRec'], resolve)

const WhAccountListView = resolve => require(['@/components/WarehouseCenter2/AccountMana/WhAccountListView'], resolve)
const WhRechargeRecView = resolve => require(['@/components/WarehouseCenter2/AccountMana/WhRechargeRecView'], resolve)
//账单管理
// const BillingRecords = resolve => require(['@/components/WarehouseCenter2/FinanceMana/BillingRecords'], resolve)
// const BillingRecordsDetList = resolve => require(['@/components/WarehouseCenter2/FinanceMana/BillingRecordsDetList'],
// 	resolve)
//账单管理-新
// const BillManage = resolve => require(['@/components/WarehouseCenter2/FinanceMana/BillManage'], resolve)
//账单首页
const BillHome = resolve => require(['@/components/WarehouseCenter2/FinanceMana/BillHome/BillHome'], resolve)

//clodop下载
const downloadCLodop = resolve => require(['@/components/WarehouseCenter2/CLodopPage/downloadCLodop'], resolve)

//代理客户的操作
//代理操作-工单
const ClientWorkOrderList = resolve => require(['@/components/StorageCenter/workOrder/ClientWorkOrderList'], resolve)
const ClientCreateWorkOrder = resolve => require(['@/components/StorageCenter/workOrder/ClientCreateWorkOrder'],
	resolve)
const ClientCreateOrder = resolve => require(['@/components/StorageCenter/workOrder/ClientCreateOrder'],
	resolve)
const ClientWorkOrderDashboard = resolve => require(['@/components/StorageCenter/workOrder/ClientWorkOrderDashboard'],
	resolve)

//第三方订单
const ThirdOrder = resolve => require(['@/components/StorageCenter/Thirdparty/ThirdOrder'], resolve)
//易仓API配置
const ThirdYiCangApiConList = resolve => require([
	'@/components/StorageCenter/Thirdparty/ThirdYiCangApiConfig/ThirdYiCangApiConList'
], resolve)
//易仓库存同步
const YiCangInventorySync = resolve => require([
	'@/components/StorageCenter/Thirdparty/ThirdYiCangApiConfig/YiCangInventorySync'
], resolve)
//Sku 拆分管理
//拆分
const WhSkuSplit = resolve => require(['@/components/WarehouseCenter2/SkuSplitGroup/WhSkuSplit'], resolve)
//拆分入库
const WhSkuSplitInStock = resolve => require(['@/components/WarehouseCenter2/SkuSplitGroup/WhSkuSplitInStock'], resolve)

//快递面单
const ExpressSheetList = resolve => require(['@/components/WarehouseCenter2/ExpressSheet/ExpressSheetList'], resolve)
const ExpressSheetDetList = resolve => require(['@/components/WarehouseCenter2/ExpressSheet/ExpressSheetDetList'],
	resolve)
const ExpressSheetAccount = resolve => require(['@/components/WarehouseCenter2/ExpressSheet/ExpressSheetAccount'],
	resolve)
const LgsTrackQuery = resolve => require(['@/components/WarehouseCenter2/ExpressSheet/LgsTrackQuery'], resolve)
//未上线快递运单
const ExpressTrunckNotOnlineList = resolve => require(['@/components/WarehouseCenter2/ExpressSheet/ExpressTrunckNotOnlineList'],resolve)
// 快递渠道绑定客户
const ExpressBindCusThrid = resolve => require(['@/components/WarehouseCenter2/ExpressSheet/ExpressBindCusThrid'], resolve)

//费用类型配置
const FeeTypeConfig = resolve => require(['@/components/WarehouseCenter2/CostConfig/FeeTypeConfig'], resolve)
//业务收费项
const FeeBusConfig = resolve => require(['@/components/WarehouseCenter2/CostConfig/FeeBusConfig'], resolve)

//第三方WMS配置
const ThirdWmsConfigList = resolve => require(['@/components/WarehouseCenter2/ThirdWmsConfig/ThirdWmsConfigList'], resolve)
const ThirdWmsAsyncInfoList = resolve => require(['@/components/WarehouseCenter2/ThirdWmsConfig/ThirdWmsAsyncInfoList'], resolve)

//第三方配置
const ThirdAuthList = resolve => require(['@/components/WarehouseCenter2/ThirdAuth/ThirdAuthList'], resolve)
const ThirdAuthListAdd = resolve => require(['@/components/WarehouseCenter2/ThirdAuth/ThirdAuthListAdd'], resolve)

//QA 服务管理
const QARecList = resolve => require(['@/components/WarehouseCenter2/QA/QARecList'], resolve)
const QARecAdd = resolve => require(['@/components/WarehouseCenter2/QA/QARecAdd'], resolve)
const QARecProgress = resolve => require(['@/components/WarehouseCenter2/QA/QARecProgress'], resolve)
//增值服务
const ValueAddServiceList = resolve => require(['@/components/WarehouseCenter2/QA/ValueAddServiceList'], resolve)

//菜单，权限配置
const MenuConf = resolve => require(['@/components/WarehouseCenter2/PermissionConfig/MenuConf'], resolve)
const PermConfAdmin = resolve => require(['@/components/WarehouseCenter2/PermissionConfig/PermConfAdmin'], resolve)
const PermConfAdminAdd = resolve => require(['@/components/WarehouseCenter2/PermissionConfig/PermConfAdminAdd'],
	resolve)
const PermConfPerm = resolve => require(['@/components/WarehouseCenter2/PermissionConfig/PermConfPerm'], resolve)
const PermConfPermAdd = resolve => require(['@/components/WarehouseCenter2/PermissionConfig/PermConfPermAdd'], resolve)
//数据字典配置
const DicConfigManage = resolve => require(['@/components/WarehouseCenter2/SysConfig/DicConfigManage'], resolve)
//用户管理
const UserMana = resolve => require(['@/components/WarehouseCenter2/SysConfig/UserMana'], resolve)
//仓库管理
const WarehouseMana = resolve => require(['@/components/WarehouseCenter2/SysConfig/WarehouseMana'], resolve)
//控制配置
const ControlConfig = resolve => require(['@/components/WarehouseCenter2/SysConfig/ControlConfig'], resolve)

//客户端菜单
//所有菜单
const allUMenus = resolve => require(['@/components/WarehouseCenter2/userMenus/allUMenus'], resolve)
//客户菜单配置
const userMenusConfig = resolve => require(['@/components/WarehouseCenter2/userMenus/userMenusConfig'], resolve)
//子账号管理
const subUserAccount = resolve => require(['@/components/WarehouseCenter2/userMenus/subUserAccount'], resolve)

//通知 
const NoticeManaList = resolve => require(['@/components/WarehouseCenter2/NoticeMana/NoticeManaList'], resolve)
const NoticeList = resolve => require(['@/components/WarehouseCenter2/NoticeMana/NoticeList'], resolve)
const NoticeDet = resolve => require(['@/components/WarehouseCenter2/NoticeMana/NoticeDet'], resolve)

//SKU关联
const WSkuRelation = resolve => require(['@/components/WarehouseCenter2/SkuProduct/WSkuRelation'], resolve)
const WSkuRelationAdd = resolve => require(['@/components/WarehouseCenter2/SkuProduct/WSkuRelation/WSkuRelationAdd'], resolve)

//托盘标
const PalletMark = resolve => require(['@/components/WarehouseCenter2/Other/PalletMark'], resolve)
//PDA,移动web
const PdaPhone = resolve => require(['@/components/WarehouseCenter2/Other/PdaPhone'], resolve)

//企业机构绑定用户
const EnterperiseBindUser = resolve => require(['@/components/WarehouseCenter2/Deal/EnterperiseBindUser'], resolve)
//企业机构绑定仓库
const EnterperiseBindWh = resolve => require(['@/components/WarehouseCenter2/Deal/EnterperiseBindWh'], resolve)
//企业仓库报价
const EnterperiseWhPrice = resolve => require(['@/components/WarehouseCenter2/Deal/EnterperiseWhPrice'], resolve)
//企业管理
const EnterpriseMana = resolve => require(['@/components/WarehouseCenter2/Deal/EnterpriseMana'], resolve)
//合伙人绑定仓库
const PartnerBindWh = resolve => require(['@/components/WarehouseCenter2/Deal/PartnerBindWh'], resolve)
//BOL.pdf测试
// const whPdfBol = resolve => require(['@/printer/whPdfBol'], resolve)

//快速转运-代理
const ClientTransportFastList = resolve => require(['@/components/StorageCenter/Transport/ClientTransportFastList'], resolve)
const TransportFastEdit = resolve => require(['@/components/StorageCenter/Transport/TransportFast/TransportFastEdit'],
	resolve)

//快速转运
const TransportFastList = resolve => require(['@/components/WarehouseCenter2/Transport/TransportFastList'], resolve)
const whPriterTransportFastDevanning = resolve => require(['@/printer/whPriterTransportFastDevanning'], resolve)

const TransportFastStatsList = resolve => require(['@/components/WarehouseCenter2/Transport/TransportFastStatsList'], resolve)

//预约提柜
// 提柜费用
const ContainerPickUpFee = resolve => require(['@/components/WarehouseCenter2/ContainerPickUp/ContainerPickUpFee'], resolve)

//库区配置
const PlaceZoneList = resolve => require(['@/components/WarehouseCenter2/PlaceZoneConfig/PlaceZoneList'], resolve)

//盘库任务
const TakingInventoryTask = resolve => require(['@/components/WarehouseCenter2/TakingInventory/TakingInventoryTask'], resolve)
const TakingInventoryTaskAdd = resolve => require(['@/components/WarehouseCenter2/TakingInventory/TakingInventoryTaskAdd'], resolve)

//偏远地区编码配置
const RemoteAreaCodingConfigList = resolve => require(['@/components/WarehouseCenter2/RemoteAreaCodingConfig/RemoteAreaCodingConfigList'], resolve)
const RemoteAreaCodingConfigAdd = resolve => require(['@/components/WarehouseCenter2/RemoteAreaCodingConfig/RemoteAreaCodingConfigAdd'], resolve)
//邮编分区配置
const PostalCodeAreaConfList = resolve => require(['@/components/WarehouseCenter2/PostalCodeAreaConf/PostalCodeAreaConfList'], resolve)
const PostalCodeAreaConfAdd = resolve => require(['@/components/WarehouseCenter2/PostalCodeAreaConf/PostalCodeAreaConfAdd'], resolve)

//渠道费用类型-新增
const CarrFeeTypePriceAdd = resolve => require(['@/components/WarehouseCenter2/thirdLogistics/CarrFeeTypePriceAdd'], resolve)
//渠道费用类型，分页列表
const CarrFeeTypePriceList = resolve => require(['@/components/WarehouseCenter2/thirdLogistics/CarrFeeTypePriceList'], resolve)

//渠道发货地址配置-新增
const CarrShipFromAddrAdd = resolve => require(['@/components/WarehouseCenter2/thirdLogistics/CarrShipFromAddrAdd'], resolve)
//渠道发货地址配置，分页列表
const CarrShipFromAddrList = resolve => require(['@/components/WarehouseCenter2/thirdLogistics/CarrShipFromAddrList'], resolve)

//提柜
//提柜供应商
const CabinetPickupCarrierList = resolve => require(['@/components/WarehouseCenter2/CabinetPickup/CabinetPickupCarrierList'], resolve)
const CabinetPickupRecList = resolve => require(['@/components/WarehouseCenter2/CabinetPickup/CabinetPickupRecList'], resolve)
const CabinetPickupRecAddBatch = resolve => require(['@/components/WarehouseCenter2/CabinetPickup/CabinetPickupRecAddBatch'], resolve)
//提柜线路配置
const CabinetPickupLineConf = resolve => require(['@/components/WarehouseCenter2/CabinetPickup/CabinetPickupLineConf'], resolve)
// const CabinetPickupLineConfAdd = resolve => require(['@/components/WarehouseCenter2/CabinetPickup/CabinetPickupLineConfAdd'], resolve)
//提柜线路价格配置
const CabinetPickupLinePriceConf = resolve => require(['@/components/WarehouseCenter2/CabinetPickup/CabinetPickupLinePriceConf'], resolve)
// const CabinetPickupLinePriceConfAdd = resolve => require(['@/components/WarehouseCenter2/CabinetPickup/CabinetPickupLinePriceConfAdd'], resolve)

//系统显示配置
const SysPageShowConfig = resolve => require(['@/components/WarehouseCenter2/SysConfig/SysPageShowConfig'], resolve)


Vue.use(Router)

const router = new Router({
	// mode: 'history',
	routes: [{
			path: '*', //其他页面，强制跳转到找不到页面
			redirect: '/NotFound'
		},
		// 登录 校验
		{
			path: '/',
			name: 'Login',
			component: Login
		},
		/*404*/
		{
			path: '/NotFound',
			name: 'NotFound',
			component: NotFound
		},
		{
			path: '/PrinterFrame',
			name: 'PrinterFrame',
			component: PrinterFrame,
			children: [
				// {
				//   path: 'whPrinterInGood',
				//   name: 'whPrinterInGood',
				//   component: whPrinterInGood
				// },
				// {
				//   path: 'whPriterShipments',
				//   name: 'whPriterShipments',
				//   component: whPriterShipments
				// },
				{
					path: 'whPrinterReceptionList',
					name: 'whPrinterReceptionList',
					component: whPrinterReceptionList
				},

				{
					path: 'whPriterPickingList',
					name: 'whPriterPickingList',
					component: whPriterPickingList
				},
				{
					path: 'whPriterTransportInList',
					name: 'whPriterTransportInList',
					component: whPriterTransportInList
				},
				{
					path: 'whPriterTransportOutList',
					name: 'whPriterTransportOutList',
					component: whPriterTransportOutList
				},
				{
					path: 'whPrinterValueAddList',
					name: 'whPrinterValueAddList',
					component: whPrinterValueAddList
				},
				{
					path: 'whPriterTransportPlanOutList',
					name: 'whPriterTransportPlanOutList',
					component: whPriterTransportPlanOutList
				},
				

				{
					path: 'whPriterDropShippingPickingList',
					name: 'whPriterDropShippingPickingList',
					component: whPriterDropShippingPickingList
				},
				// {
				// 	path: 'whPriterDropShippingPickingListByWorkNo',
				// 	name: 'whPriterDropShippingPickingListByWorkNo',
				// 	component: whPriterDropShippingPickingListByWorkNo
				// },
				{
					path: 'whPriterTransportFastDevanning',
					name: 'whPriterTransportFastDevanning',
					component: whPriterTransportFastDevanning
				},
				
				// {
				// 	path: 'whPdfBol',
				// 	name: 'whPdfBol',
				// 	component: whPdfBol
				// },
				
				
				// {
				//   path: 'whPriterDropFBAPicking',
				//   name: 'whPriterDropFBAPicking',
				//   component: whPriterDropFBAPicking
				// },




			]
		},
		//页面主框架

		{
			path: '/Frame',
			name: 'Frame',
			component: Frame,
			children: [{
					path: 'Home',
					name: 'Home',
					component: Home
				},
				{
					path: 'Help',
					name: 'Help',
					component: Help
				},
				{
					path: 'Workbench',
					name: 'Workbench',
					component: Workbench
				},
				{
					path: 'mapAddressDistance',
					name: 'mapAddressDistance',
					component: mapAddressDistance
				},
				{
					path: 'WhCalendar',
					name: 'WhCalendar',
					component: WhCalendar
				},
				
				{
					path: 'WhCustomer',
					name: 'WhCustomer',
					component: WhCustomer
				},
				{
					path: 'WhCustomerAll',
					name: 'WhCustomerAll',
					component: WhCustomerAll
				},
				
				{
					path: 'WhFeeRecordList',
					name: 'WhFeeRecordList',
					component: WhFeeRecordList
				},

				{
					path: 'WhInGoods2',
					name: 'WhInGoods2',
					component: WhInGoods2
				}, 
				// {
				// 	path: 'WhInGoodsDetList2',
				// 	name: 'WhInGoodsDetList2',
				// 	component: WhInGoodsDetList2
				// },
				
				{
					path: 'WhInGoodsDetAudit',
					name: 'WhInGoodsDetAudit',
					component: WhInGoodsDetAudit
				},
				{
					path: 'WhInsStockRecord',
					name: 'WhInsStockRecord',
					component: WhInsStockRecord
				},
				// {
				//   path: 'WhInventoryCollection',
				//   name: 'WhInventoryCollection',
				//   component: WhInventoryCollection
				// },

				{
					path: 'WhInShipmentsCommand',
					name: 'WhInShipmentsCommand',
					component: WhInShipmentsCommand
				},
				// {
				// 	path: 'WhInShipmentsComDetList',
				// 	name: 'WhInShipmentsComDetList',
				// 	component: WhInShipmentsComDetList
				// },
				// {
				// 	path: 'WhInShipmentsComDetListPicking',
				// 	name: 'WhInShipmentsComDetListPicking',
				// 	component: WhInShipmentsComDetListPicking
				// },
				{
					path: 'WhInShipmentsScanPrinteFile',
					name: 'WhInShipmentsScanPrinteFile',
					component: WhInShipmentsScanPrinteFile
				},
				
				// {
				// 	path: 'WhInShipmentsPallet',
				// 	name: 'WhInShipmentsPallet',
				// 	component: WhInShipmentsPallet
				// },

				// {
				// 	path: 'fbaTransshipment',
				// 	name: 'fbaTransshipment',
				// 	component: fbaTransshipment
				// },

				// {
				// 	path: 'selfPickUp',
				// 	name: 'selfPickUp',
				// 	component: selfPickUp
				// },


				// {
				// 	path: 'WhInSetOutPlace',
				// 	name: 'WhInSetOutPlace',
				// 	component: WhInSetOutPlace
				// },
				{
					path: 'WhDropShipping',
					name: 'WhDropShipping',
					component: WhDropShipping
				},
				{
					path: 'WhDropShippingDashboard',
					name: 'WhDropShippingDashboard',
					component: WhDropShippingDashboard
				},
				{
					path: 'WhDropShippingBatch',
					name: 'WhDropShippingBatch',
					component: WhDropShippingBatch
				},

				{
					path: 'WhDropShippingDet',
					name: 'WhDropShippingDet',
					component: WhDropShippingDet
				},

				{
					path: 'WhDropShippingBatchDet',
					name: 'WhDropShippingBatchDet',
					component: WhDropShippingBatchDet
				},

				{
					path: 'WhDropShippingBatchScanPrint',
					name: 'WhDropShippingBatchScanPrint',
					component: WhDropShippingBatchScanPrint
				},
				
				{
					path: 'WhDsOLpnScanPrint',
					name: 'WhDsOLpnScanPrint',
					component: WhDsOLpnScanPrint
				},
				
				// {
				//   path: 'PrtExpressSheetRec',
				//   name: 'PrtExpressSheetRec',
				//   component: PrtExpressSheetRec
				// },

				{
					path: 'PriterOrderDetList',
					name: 'PriterOrderDetList',
					component: PriterOrderDetList
				},

				{
					path: 'ExchangeReturnList',
					name: 'ExchangeReturnList',
					component: ExchangeReturnList
				},
				{
					path: 'ExchangeReturnInsStock',
					name: 'ExchangeReturnInsStock',
					component: ExchangeReturnInsStock
				},

				{
					path: 'TransportInWh',
					name: 'TransportInWh',
					component: TransportInWh
				},
				{
					path: 'TransportInWhDetList',
					name: 'TransportInWhDetList',
					component: TransportInWhDetList
				},
				{
					path: 'TransportInWhDetAudit',
					name: 'TransportInWhDetAudit',
					component: TransportInWhDetAudit
				},
				
				{
					path: 'TransportOutWh',
					name: 'TransportOutWh',
					component: TransportOutWh
				},
				// {
				// 	path: 'TransportOutWhEdit',
				// 	name: 'TransportOutWhEdit',
				// 	component: TransportOutWhEdit
				// },
				{
					path: 'TransportOutWhDetList',
					name: 'TransportOutWhDetList',
					component: TransportOutWhDetList
				},
				
				{
					path: 'TransportInventories',
					name: 'TransportInventories',
					component: TransportInventories
				},
				
				
				{
					path: 'TransportPlanOutWh',
					name: 'TransportPlanOutWh',
					component: TransportPlanOutWh
				},
				
				{
					path: 'ClientTransportPlanOutWh',
					name: 'ClientTransportPlanOutWh',
					component: ClientTransportPlanOutWh
				},
				{
					path: 'TransportPlanOutWhEdit',
					name: 'TransportPlanOutWhEdit',
					component: TransportPlanOutWhEdit
				},

				{
					path: 'ClientTransportInWh',
					name: 'ClientTransportInWh',
					component: ClientTransportInWh
				},
				{
					path: 'ClientTransportInWhEdit',
					name: 'ClientTransportInWhEdit',
					component: ClientTransportInWhEdit
				},
				{
					path: 'ClientTransportInWhDetList',
					name: 'ClientTransportInWhDetList',
					component: ClientTransportInWhDetList
				},
				{
					path: 'ClientTransportInWhByToOut',
					name: 'ClientTransportInWhByToOut',
					component: ClientTransportInWhByToOut
				},
				{
					path: 'ClientTransportOutWh',
					name: 'ClientTransportOutWh',
					component: ClientTransportOutWh
				},
				{
					path: 'ClientTransportOutWhEdit',
					name: 'ClientTransportOutWhEdit',
					component: ClientTransportOutWhEdit
				},
				{
					path: 'ClientTransportOutWhDetList',
					name: 'ClientTransportOutWhDetList',
					component: ClientTransportOutWhDetList
				},
				//拆分-代理
				{
					path: 'whSkuSplitAgent',
					name: 'whSkuSplitAgent',
					component: whSkuSplitAgent
				},
				// {
				// 	path: 'ClientTransportInventories',
				// 	name: 'ClientTransportInventories',
				// 	component: ClientTransportInventories
				// },


				{
					path: 'WhWorkOrderList',
					name: 'WhWorkOrderList',
					component: WhWorkOrderList
				},
				{
					path: 'WhWorkOrderListDet',
					name: 'WhWorkOrderListDet',
					component: WhWorkOrderListDet
				},
				// {
				// 	path: 'WorkDropShipping',
				// 	name: 'WorkDropShipping',
				// 	component: WorkDropShipping
				// },
				// {
				// 	path: 'WorkDropShippingList',
				// 	name: 'WorkDropShippingList',
				// 	component: WorkDropShippingList
				// },
				// {
				// 	path: 'WorkTransshipmentList',
				// 	name: 'WorkTransshipmentList',
				// 	component: WorkTransshipmentList
				// },
				// {
				// 	path: 'WorkWhPacingList',
				// 	name: 'WorkWhPacingList',
				// 	component: WorkWhPacingList
				// },
				// {
				// 	path: 'WorkExchangeReturnList',
				// 	name: 'WorkExchangeReturnList',
				// 	component: WorkExchangeReturnList
				// },




				{
					path: 'WhExceptionalEvent',
					name: 'WhExceptionalEvent',
					component: WhExceptionalEvent
				},
				{
					path: 'WhExceptionalEventRecord',
					name: 'WhExceptionalEventRecord',
					component: WhExceptionalEventRecord
				},

				// {
				// 	path: 'WhCustomerSkuList',
				// 	name: 'WhCustomerSkuList',
				// 	component: WhCustomerSkuList
				// },
				{
					path: 'WhCustomerSkuInventories',
					name: 'WhCustomerSkuInventories',
					component: WhCustomerSkuInventories
				},
				{
					path: 'WhCustomerSkuInventoriesChangeStock',
					name: 'WhCustomerSkuInventoriesChangeStock',
					component: WhCustomerSkuInventoriesChangeStock
				},
				{
					path: 'WhSkuStockLock',
					name: 'WhSkuStockLock',
					component: WhSkuStockLock
				},
				{
					path: 'WhStockList',
					name: 'WhStockList',
					component: WhStockList
				},
				{
					path: 'WhStockCheck',
					name: 'WhStockCheck',
					component: WhStockCheck
				},
				
				{
					path: 'WhCustomerSkuRecord',
					name: 'WhCustomerSkuRecord',
					component: WhCustomerSkuRecord
				},

				{
					path: 'WSkuInfo',
					name: 'WSkuInfo',
					component: WSkuInfo
				},




				{
					path: 'WhFinance',
					name: 'WhFinance',
					component: WhFinance
				},

				{
					path: 'WhFinanceAdd',
					name: 'WhFinanceAdd',
					component: WhFinanceAdd
				},

				{
					path: 'WhFinanceDetList',
					name: 'WhFinanceDetList',
					component: WhFinanceDetList
				},
				{
					path: 'WhFinanceAdditionalRec',
					name: 'WhFinanceAdditionalRec',
					component: WhFinanceAdditionalRec
				},
				{
					path: 'AccountPayableList',
					name: 'AccountPayableList',
					component: AccountPayableList
				},
				{
					path: 'AccountPayableListAdd',
					name: 'AccountPayableListAdd',
					component: AccountPayableListAdd
				},
				
				{
					path: 'AccountReceivableList',
					name: 'AccountReceivableList',
					component: AccountReceivableList
				},
				
				// {
				// 	path: 'BillManage',
				// 	name: 'BillManage',
				// 	component: BillManage
				// },
				{
					path: 'BillHome',
					name: 'BillHome',
					component: BillHome
				},

				// {
				// 	path: 'BillingRecords',
				// 	name: 'BillingRecords',
				// 	component: BillingRecords
				// },
				// {
				// 	path: 'BillingRecordsDetList',
				// 	name: 'BillingRecordsDetList',
				// 	component: BillingRecordsDetList
				// },


				{
					path: 'WhPlace',
					name: 'WhPlace',
					component: WhPlace
				},
				{
					path: 'WhPlaceCharts',
					name: 'WhPlaceCharts',
					component: WhPlaceCharts
				},
				
				{
					path: 'InventoryStatisticsSkuGroup',
					name: 'InventoryStatisticsSkuGroup',
					component: InventoryStatisticsSkuGroup
				},
				{
					path: 'InventoryNow',
					name: 'InventoryNow',
					component: InventoryNow
				},

				{
					path: 'InventoryStatistics',
					name: 'InventoryStatistics',
					component: InventoryStatistics
				},
				{
					path: 'outBill',
					name: 'outBill',
					component: outBill
				},
				{
					path: 'OrderStatisticsByMon',
					name: 'OrderStatisticsByMon',
					component: OrderStatisticsByMon
				},
				{
					path: 'CargoVolumeStatisticsByDay',
					name: 'CargoVolumeStatisticsByDay',
					component: CargoVolumeStatisticsByDay
				},
				
				// {
				//   path: 'OrderStatisticsByDay',
				//   name: 'OrderStatisticsByDay',
				//   component: OrderStatisticsByDay
				// },


				{
					path: 'WhCostStatistics',
					name: 'WhCostStatistics',
					component: WhCostStatistics
				},
				{
					path: 'ChargeConfiguration',
					name: 'ChargeConfiguration',
					component: ChargeConfiguration
				},
				{
					path: 'AccountBindWh',
					name: 'AccountBindWh',
					component: AccountBindWh
				},
				{
					path: 'CustomerBindWh',
					name: 'CustomerBindWh',
					component: CustomerBindWh
				},
				{
					path: 'CustomerBindWhAdmin',
					name: 'CustomerBindWhAdmin',
					component: CustomerBindWhAdmin
				},
				
				{
					path: 'QuotationConfig',
					name: 'QuotationConfig',
					component: QuotationConfig
				},
				{
					path: 'WhAccountList',
					name: 'WhAccountList',
					component: WhAccountList
				},
				{
					path: 'WhAccountRec',
					name: 'WhAccountRec',
					component: WhAccountRec
				},
				{
					path: 'WhRechargeRec',
					name: 'WhRechargeRec',
					component: WhRechargeRec
				},
				
				{
					path: 'WhAccountListView',
					name: 'WhAccountListView',
					component: WhAccountListView
				},
				
				{
					path: 'WhRechargeRecView',
					name: 'WhRechargeRecView',
					component: WhRechargeRecView
				},
				
				{
					path: 'downloadCLodop',
					name: 'downloadCLodop',
					component: downloadCLodop
				},

				{
					path: 'ClientWorkOrderList',
					name: 'ClientWorkOrderList',
					component: ClientWorkOrderList
				},
				{
					path: 'ClientCreateWorkOrder',
					name: 'ClientCreateWorkOrder',
					component: ClientCreateWorkOrder
				},
				{
					path: 'ClientCreateOrder',
					name: 'ClientCreateOrder',
					component: ClientCreateOrder
				},
				{
					path: 'ClientWorkOrderDashboard',
					name: 'ClientWorkOrderDashboard',
					component: ClientWorkOrderDashboard
				},

				{
					path: 'ThirdOrder',
					name: 'ThirdOrder',
					component: ThirdOrder
				},

				{
					path: 'ThirdYiCangApiConList',
					name: 'ThirdYiCangApiConList',
					component: ThirdYiCangApiConList
				},
				{
					path: 'YiCangInventorySync',
					name: 'YiCangInventorySync',
					component: YiCangInventorySync
				},

				{
					path: 'WhSkuSplit',
					name: 'WhSkuSplit',
					component: WhSkuSplit
				},
				{
					path: 'WhSkuSplitInStock',
					name: 'WhSkuSplitInStock',
					component: WhSkuSplitInStock
				},
				{
					path: 'ExpressSheetList',
					name: 'ExpressSheetList',
					component: ExpressSheetList
				},
				{
					path: 'LgsTrackQuery',
					name: 'LgsTrackQuery',
					component: LgsTrackQuery
				},

				{
					path: 'ExpressSheetDetList',
					name: 'ExpressSheetDetList',
					component: ExpressSheetDetList
				},
				{
					path: 'ExpressTrunckNotOnlineList',
					name: 'ExpressTrunckNotOnlineList',
					component: ExpressTrunckNotOnlineList
				},
				
				{
					path: 'ExpressSheetAccount',
					name: 'ExpressSheetAccount',
					component: ExpressSheetAccount
				},
				{
					path: 'ExpressBindCusThrid',
					name: 'ExpressBindCusThrid',
					component: ExpressBindCusThrid
				},
				
				{
					path: 'FeeTypeConfig',
					name: 'FeeTypeConfig',
					component: FeeTypeConfig
				},
				{
					path: 'FeeBusConfig',
					name: 'FeeBusConfig',
					component: FeeBusConfig
				},
				{
					path: 'ThirdWmsConfigList',
					name: 'ThirdWmsConfigList',
					component: ThirdWmsConfigList
				},
				{
					path: 'ThirdWmsAsyncInfoList',
					name: 'ThirdWmsAsyncInfoList',
					component: ThirdWmsAsyncInfoList
				},
				{
					path: 'ThirdAuthList',
					name: 'ThirdAuthList',
					component: ThirdAuthList
				},
				{
					path: 'ThirdAuthListAdd',
					name: 'ThirdAuthListAdd',
					component: ThirdAuthListAdd
				},
				{
					path: 'QARecList',
					name: 'QARecList',
					component: QARecList
				},
				{
					path: 'QARecAdd',
					name: 'QARecAdd',
					component: QARecAdd
				},
				{
					path: 'QARecProgress',
					name: 'QARecProgress',
					component: QARecProgress
				},
				{
					path: 'ValueAddServiceList',
					name: 'ValueAddServiceList',
					component: ValueAddServiceList
				},

				{
					path: 'MenuConf',
					name: 'MenuConf',
					component: MenuConf
				},
				{
					path: 'PermConfAdmin',
					name: 'PermConfAdmin',
					component: PermConfAdmin
				},
				{
					path: 'PermConfAdminAdd',
					name: 'PermConfAdminAdd',
					component: PermConfAdminAdd
				},
				{
					path: 'PermConfPerm',
					name: 'PermConfPerm',
					component: PermConfPerm
				},
				{
					path: 'PermConfPermAdd',
					name: 'PermConfPermAdd',
					component: PermConfPermAdd
				},
				{
					path: 'DicConfigManage',
					name: 'DicConfigManage',
					component: DicConfigManage
				},
				{
					path: 'UserMana',
					name: 'UserMana',
					component: UserMana
				},
				{
					path: 'WarehouseMana',
					name: 'WarehouseMana',
					component: WarehouseMana
				},
				{
					path: 'ControlConfig',
					name: 'ControlConfig',
					component: ControlConfig
				},
				{
					path: 'allUMenus',
					name: 'allUMenus',
					component: allUMenus
				},
				{
					path: 'userMenusConfig',
					name: 'userMenusConfig',
					component: userMenusConfig
				},

				{
					path: 'subUserAccount',
					name: 'subUserAccount',
					component: subUserAccount
				},
				{
					path: 'NoticeManaList',
					name: 'NoticeManaList',
					component: NoticeManaList
				},
				{
					path: 'NoticeList',
					name: 'NoticeList',
					component: NoticeList
				},
				{
					path: 'NoticeDet',
					name: 'NoticeDet',
					component: NoticeDet
				},
				{
					path: 'WSkuRelation',
					name: 'WSkuRelation',
					component: WSkuRelation,
					// meta: {
					// 	isMenu: true
					// }
				},
				{
					path: 'WSkuRelationAdd',
					name: 'WSkuRelationAdd',
					component: WSkuRelationAdd,
					// meta: {
					// 	isMenu: false
					// }
				},
				{
					path: 'PalletMark',
					name: 'PalletMark',
					component: PalletMark
				},
				{
					path: 'PdaPhone',
					name: 'PdaPhone',
					component: PdaPhone
				},
				
				{
					path: 'EnterperiseBindUser',
					name: 'EnterperiseBindUser',
					component: EnterperiseBindUser
				},
				{
					path: 'EnterperiseBindWh',
					name: 'EnterperiseBindWh',
					component: EnterperiseBindWh
				},
				{
					path: 'EnterperiseWhPrice',
					name: 'EnterperiseWhPrice',
					component: EnterperiseWhPrice
				},
				{
					path: 'EnterpriseMana',
					name: 'EnterpriseMana',
					component: EnterpriseMana
				},
				{
					path: 'PartnerBindWh',
					name: 'PartnerBindWh',
					component: PartnerBindWh
				},
				
				{
					path: 'TransportFastList',
					name: 'TransportFastList',
					component: TransportFastList
				},
				{
					path: 'ClientTransportFastList',
					name: 'ClientTransportFastList',
					component: ClientTransportFastList
				},
				{
					path: 'TransportFastStatsList',
					name: 'TransportFastStatsList',
					component: TransportFastStatsList
				},
				
				
				{
					path: 'TransportFastEdit',
					name: 'TransportFastEdit',
					component: TransportFastEdit
				},
				{
					path: 'ContainerPickUpFee',
					name: 'ContainerPickUpFee',
					component: ContainerPickUpFee
				},
				{
					path: 'PlaceZoneList',
					name: 'PlaceZoneList',
					component: PlaceZoneList
				},
				{
					path: 'TakingInventoryTask',
					name: 'TakingInventoryTask',
					component: TakingInventoryTask
				},
				{
					path: 'TakingInventoryTaskAdd',
					name: 'TakingInventoryTaskAdd',
					component: TakingInventoryTaskAdd
				},
				{
					path: 'RemoteAreaCodingConfigList',
					name: 'RemoteAreaCodingConfigList',
					component: RemoteAreaCodingConfigList
				},
				{
					path: 'RemoteAreaCodingConfigAdd',
					name: 'RemoteAreaCodingConfigAdd',
					component: RemoteAreaCodingConfigAdd
				},
				{
					path: 'PostalCodeAreaConfList',
					name: 'PostalCodeAreaConfList',
					component: PostalCodeAreaConfList
				},
				{
					path: 'PostalCodeAreaConfAdd',
					name: 'PostalCodeAreaConfAdd',
					component: PostalCodeAreaConfAdd
				},
				
				{
					path: 'CarrFeeTypePriceAdd',
					name: 'CarrFeeTypePriceAdd',
					component: CarrFeeTypePriceAdd
				},
				{
					path: 'CarrFeeTypePriceList',
					name: 'CarrFeeTypePriceList',
					component: CarrFeeTypePriceList
				},
				{
					path: 'CarrShipFromAddrAdd',
					name: 'CarrShipFromAddrAdd',
					component: CarrShipFromAddrAdd
				},
				{
					path: 'CarrShipFromAddrList',
					name: 'CarrShipFromAddrList',
					component: CarrShipFromAddrList
				},
				
				{
					path: 'CabinetPickupCarrierList',
					name: 'CabinetPickupCarrierList',
					component: CabinetPickupCarrierList
				},
				{
					path: 'CabinetPickupRecList',
					name: 'CabinetPickupRecList',
					component: CabinetPickupRecList
				},
				{
					path: 'CabinetPickupRecAddBatch',
					name: 'CabinetPickupRecAddBatch',
					component: CabinetPickupRecAddBatch
				},
				{
					path: 'CabinetPickupLineConf',
					name: 'CabinetPickupLineConf',
					component: CabinetPickupLineConf
				},
				{
					path: 'CabinetPickupLinePriceConf',
					name: 'CabinetPickupLinePriceConf',
					component: CabinetPickupLinePriceConf
				},
				{
					path: 'SysPageShowConfig',
					name: 'SysPageShowConfig',
					component: SysPageShowConfig
				},
				
				
				
				
				
			]
		},
		//授权页面
		// {
		// 	path: '/AuthFrame',
		// 	name: 'AuthFrame',
		// 	component: AuthFrame,
		// 	children: [{
		// 		path: 'UpsOauth',
		// 		name: 'UpsOauth',
		// 		component: UpsOauth
		// 	},{
		// 		path: 'UpsOauthBack',
		// 		name: 'UpsOauthBack',
		// 		component: UpsOauthBack
		// 	}],
		// }
	],
	//滚动行为
	scrollBehavior(to, from, savedPosition) {
		// return 期望滚动到哪个的位置
		if (savedPosition) { //浏览器前进后退
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
});

const whiteList = ['Login','NotFound']

// 全局导航钩子
router.beforeEach((to, from, next) => {
	console.log('全局导航钩子', to);
	//更新logo显示
	store.dispatch('UPDATE_SHOWCONF');
	console.log('sysShow',store.state.sysShow);
	
	let MenusInfoKey = store.getters.getMenusInfoKey;
	let authList = [];
	if (MenusInfoKey) {
		authList = whiteList.concat(MenusInfoKey);
	} else {
		authList = whiteList;
	}
	if (!authList.includes(to.name)) { //不存在
		console.log('不存在 NotFound');
		next({
			path: '/NotFound'
		})
	}
	next();
});


export default router
